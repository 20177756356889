import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  FaFilePdf,
  FaFilePowerpoint,
  FaFileWord,
  FaFileImage,
  FaFileAlt,
  FaUpload,
  FaTrash,
  FaArrowUp,
  FaArrowDown,
  FaCopy,
  FaLink,
} from "react-icons/fa";
import "../css/dashboard/SyncLinkShareCreator.css";
import FileUploadStatus from "../components/synclinkcreate/FileUploadStatus";
import "../css/dashboard/LinkGenerator.css"; // Reuse the same CSS
import { FaCheck, FaEye, FaPlus } from "react-icons/fa";
import { FRONTEND_URL, API_URL } from "../constants/constants";
import DashboardHeader from "../components/dashboard/DashboardHeader";

// Define file limits
const MAX_FILE_SIZE_MB = 50; // Maximum file size in MB
const MAX_FILES_COUNT = 10; // Maximum number of files allowed
const MAX_TOTAL_SIZE_MB = 200; // Maximum total size of all files in MB

// Helper function to get file icon and color by file type or name
export const getFileIconInfo = (file) => {
  const fileType = file.fileType || file.type || "";
  const fileName = file.originalName || file.name || "";

  // First check file type
  if (fileType.includes("pdf") || fileName.toLowerCase().endsWith(".pdf")) {
    return { icon: <FaFilePdf />, color: "#e74c3c", label: "PDF" };
  }

  if (
    fileType.includes("powerpoint") ||
    fileType.includes("presentation") ||
    fileName.toLowerCase().match(/\.(ppt|pptx)$/)
  ) {
    return {
      icon: <FaFilePowerpoint />,
      color: "#f39c12",
      label: "Presentation",
    };
  }

  if (
    fileType.includes("word") ||
    fileType.includes("document") ||
    fileName.toLowerCase().match(/\.(doc|docx)$/)
  ) {
    return { icon: <FaFileWord />, color: "#3498db", label: "Document" };
  }

  if (
    fileType.includes("image") ||
    fileName.toLowerCase().match(/\.(jpg|jpeg|png|gif|bmp|svg|webp)$/)
  ) {
    return { icon: <FaFileImage />, color: "#27ae60", label: "Image" };
  }

  if (
    fileType.includes("spreadsheet") ||
    fileType.includes("excel") ||
    fileName.toLowerCase().match(/\.(xls|xlsx|csv)$/)
  ) {
    return { icon: <FaFileAlt />, color: "#2ecc71", label: "Spreadsheet" };
  }

  if (
    fileType.includes("zip") ||
    fileType.includes("archive") ||
    fileName.toLowerCase().match(/\.(zip|rar|7z|tar|gz)$/)
  ) {
    return { icon: <FaFileAlt />, color: "#8e44ad", label: "Archive" };
  }

  if (
    fileType.includes("text") ||
    fileType.includes("plain") ||
    fileName.toLowerCase().match(/\.(txt|md|rtf)$/)
  ) {
    return { icon: <FaFileAlt />, color: "#7f8c8d", label: "Text" };
  }

  return { icon: <FaFileAlt />, color: "#95a5a6", label: "File" };
};

// Helper to format file size
export const formatFileSize = (bytes) => {
  if (!bytes) return "Unknown size";

  const kb = bytes / 1024;
  if (kb < 1024) {
    return `${kb.toFixed(1)} KB`;
  }

  const mb = kb / 1024;
  return `${mb.toFixed(1)} MB`;
};

const SyncLinkShareCreator = () => {
  // get token
  const getToken = () => {
    const name = "token=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  };

  const { linkId } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [createdLinkUrl, setCreatedLinkUrl] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [currentTotalSize, setCurrentTotalSize] = useState(0);
  const [connectionStatus, setConnectionStatus] = useState({
    checking: false,
    cloudinary: null,
    database: null,
  });

  // New state for upload progress tracking
  const [uploadProgress, setUploadProgress] = useState([]);
  const [showUploadStatus, setShowUploadStatus] = useState(false);

  // For file input reference
  const fileInputRef = useRef(null);

  // For drag-and-drop reordering
  const [draggedItem, setDraggedItem] = useState(null);

  // Calculate total size of files
  useEffect(() => {
    const calculateTotalSize = () => {
      let totalSize = 0;
      files.forEach((file) => {
        if (file.size) {
          totalSize += file.size;
        } else if (file.sizeInBytes) {
          totalSize += file.sizeInBytes;
        }
      });

      setCurrentTotalSize(totalSize);
    };

    calculateTotalSize();
  }, [files]);

  // Check connections on component mount
  // useEffect(() => {
  //   const checkConnections = async () => {
  //     setConnectionStatus((prev) => ({ ...prev, checking: true }));

  //     try {
  //       // Check Cloudinary connection
  //       const cloudinaryResponse = await axios.get(
  //         `${API_URL}/test-cloudinary`
  //       );
  //       setConnectionStatus((prev) => ({
  //         ...prev,
  //         cloudinary: cloudinaryResponse.data.success,
  //       }));

  //       // Check database connection
  //       const dbResponse = await axios.get(`${API_URL}/test-database`);
  //       setConnectionStatus((prev) => ({
  //         ...prev,
  //         database: dbResponse.data.success,
  //       }));
  //     } catch (err) {
  //       console.error("Error checking connections:", err);
  //       setConnectionStatus((prev) => ({
  //         ...prev,
  //         cloudinary: false,
  //         database: false,
  //       }));
  //     } finally {
  //       setConnectionStatus((prev) => ({ ...prev, checking: false }));
  //     }
  //   };

  //   checkConnections();
  // }, []);

  // Fetch existing synclinkshare data if in edit mode
  useEffect(() => {
    const fetchSyncLink = async () => {
      // Only fetch if we have a linkId (edit mode)
      if (!linkId) {
        setIsEditMode(false);
        return;
      }

      setLoading(true);
      setIsEditMode(true);

      try {
        const response = await axios.get(`${API_URL}/synclinkshare/${linkId}`);
        const syncLink = response.data;

        setTitle(syncLink.title || "");
        setDescription(syncLink.description || "");
        setFiles(syncLink.files || []);
      } catch (err) {
        console.error("Error fetching synclinkshare:", err);
        setError(
          "Failed to load Synclink for editing. It may have been deleted or you don't have permission to access it."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchSyncLink();
  }, [linkId]);

  // Validate files before upload
  const validateFiles = (filesToValidate) => {
    const errors = [];
    let totalSize = currentTotalSize;

    // Allowed file types (client-side check)
    const allowedTypes = [
      "application/pdf",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "image/jpeg",
      "image/png",
      "image/gif",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    // Check if adding these files would exceed the maximum count
    if (files.length + filesToValidate.length > MAX_FILES_COUNT) {
      errors.push(`You can only upload a maximum of ${MAX_FILES_COUNT} files.`);
    }

    // Check individual file sizes and calculate total size
    for (const file of filesToValidate) {
      const fileSizeMB = file.size / (1024 * 1024);
      totalSize += file.size;

      // Check file type
      if (!allowedTypes.includes(file.type)) {
        errors.push(
          `File "${file.name}" has unsupported format. Only PDF, Office documents, images, and certain other formats are allowed.`
        );
      }

      if (fileSizeMB > MAX_FILE_SIZE_MB) {
        errors.push(
          `File "${file.name}" exceeds the maximum file size of ${MAX_FILE_SIZE_MB}MB.`
        );
      }
    }

    // Check if total size exceeds limit
    const totalSizeMB = totalSize / (1024 * 1024);
    if (totalSizeMB > MAX_TOTAL_SIZE_MB) {
      errors.push(
        `Total file size exceeds the maximum limit of ${MAX_TOTAL_SIZE_MB}MB.`
      );
    }

    return {
      valid: errors.length === 0,
      errors: errors.join(" "),
    };
  };

  // Function to simulate upload progress for testing
  const simulateUploadProgress = (filesArray) => {
    // For each file, create an interval to update progress
    filesArray.forEach((file) => {
      let progress = 0;
      const interval = setInterval(() => {
        // Random progress increment to simulate variable network speeds
        progress += Math.floor(Math.random() * 10) + 5;

        if (progress >= 100) {
          progress = 100;
          clearInterval(interval);

          // Mark file as complete
          setUploadProgress((prev) =>
            prev.map((f) =>
              f.id === file.id
                ? { ...f, progress: 100, status: "completed" }
                : f
            )
          );

          // Check if all files are complete
          setTimeout(() => {
            setUploadProgress((prev) => {
              const allComplete = prev.every((f) => f.progress === 100);
              if (allComplete) {
                // Close the upload status after a delay
                setTimeout(() => {
                  setShowUploadStatus(false);
                }, 3000);
              }
              return prev;
            });
          }, 500);
        } else {
          // Update progress for this file
          setUploadProgress((prev) =>
            prev.map((f) => (f.id === file.id ? { ...f, progress } : f))
          );
        }
      }, 300 + Math.random() * 200); // Random interval for more realistic effect
    });
  };

  // Handle closing the upload status display
  const handleCloseUploadStatus = () => {
    setShowUploadStatus(false);
  };

  // Updated handleFileSelect function with progress tracking
  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length === 0) return;

    // Validate files
    const validation = validateFiles(selectedFiles);
    if (!validation.valid) {
      setError(validation.errors);
      return;
    }

    setUploading(true);
    setError(null);

    try {
      // Create files with IDs for tracking upload progress
      const newFiles = selectedFiles.map((file, index) => {
        const fileId = Math.random().toString(36).substring(2, 11);
        return {
          id: fileId,
          originalName: file.name,
          fileType: file.type,
          size: file.size,
          file: file, // Store the actual file object for later upload
          order: files.length + index,
          progress: 0, // Add progress property for tracking uploads
        };
      });

      // Update files state
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);

      // Set up upload progress tracking
      setUploadProgress(newFiles);
      setShowUploadStatus(true);

      // Simulate initial progress (in a real app this would come from the actual upload)
      simulateUploadProgress(newFiles);

      setSuccess("Files added successfully");

      // Clear success message after 3 seconds
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      console.error("Error adding files:", err);
      // Handle different error formats
      let errorMessage = "Failed to add files. Please try again.";
      if (err.message) {
        errorMessage = err.message;
      }
      setError(errorMessage);
    } finally {
      setUploading(false);
      // Reset the file input so the same file can be selected again if needed
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const moveFileUp = (index) => {
    if (index === 0) return; // Already at the top

    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      const temp = newFiles[index];
      newFiles[index] = newFiles[index - 1];
      newFiles[index - 1] = temp;
      return newFiles;
    });
  };

  const moveFileDown = (index) => {
    if (index === files.length - 1) return; // Already at the bottom

    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      const temp = newFiles[index];
      newFiles[index] = newFiles[index + 1];
      newFiles[index + 1] = temp;
      return newFiles;
    });
  };

  const copyLinkToClipboard = () => {
    if (createdLinkUrl) {
      navigator.clipboard
        .writeText(createdLinkUrl)
        .then(() => {
          setLinkCopied(true);
          setTimeout(() => setLinkCopied(false), 2000);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };

  const createSyncLink = async (e) => {
    e.preventDefault();

    if (!title.trim()) {
      setError("Please provide a title for your Synclink");
      return;
    }

    if (files.length === 0) {
      setError("Please upload at least one file");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Check if we have any files that need to be uploaded first
      const filesToUpload = files.filter((file) => file.file !== undefined);

      // If we have files to upload, we need to use FormData
      if (filesToUpload.length > 0) {
        const formData = new FormData();
        // Setup for tracking upload progress
        const uploadFilesWithProgress = filesToUpload.map((file) => ({
          id: file.id || Math.random().toString(36).substring(2, 11),
          originalName: file.originalName || file.name,
          fileType: file.fileType || file.type,
          size: file.size,
          progress: 0,
        }));

        setUploadProgress(uploadFilesWithProgress);
        setShowUploadStatus(true);

        // Add each file explicitly with a unique name to avoid conflicts
        filesToUpload.forEach((fileObj) => {
          formData.append("files", fileObj.file);
        });

        // Add metadata as string values
        formData.append("title", title || "Untitled Synclink");
        formData.append("description", description || "");

        // formData.append("userId", "jbans");

        // If we're updating an existing link
        if (isEditMode && linkId) {
          formData.append("linkId", linkId);
        }

        // Log FormData entries - note this will only show the file names, not contents
        const formDataEntries = [];
        for (let [key, value] of formData.entries()) {
          if (value instanceof File) {
            formDataEntries.push([key, `File: ${value.name}`]);
          } else {
            formDataEntries.push([key, value]);
          }
        }

        // Upload files with the synclinkshare creation in one step
        const response = await axios.post(
          `${API_URL}/synclinkshare/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              token: getToken(),
            },
            withCredentials: true,
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              // Update all files with the same progress
              setUploadProgress((prev) =>
                prev.map((file) => ({ ...file, progress: percentCompleted }))
              );

              console.log(formData, "::::::::");

              if (percentCompleted === 100) {
                // Mark all as complete once the upload finishes
                setTimeout(() => {
                  setUploadProgress((prev) =>
                    prev.map((file) => ({ ...file, status: "completed" }))
                  );

                  // Don't close status yet - keep it visible for confirmation
                }, 500);
              }
            },
          }
        );

        const responseData = response.data;

        // Set success and link URL
        setSuccess(
          isEditMode
            ? "Sync Link created successfully"
            : "Sync Link created successfully"
        );

        if (responseData.url) {
          setCreatedLinkUrl(responseData.url);
        } else if (responseData.linkId) {
          setCreatedLinkUrl(
            `${window.location.origin}/synclinkshare/view/${responseData.linkId}`
          );
        }
      } else {
        // No new files to upload, just update metadata
        let response;

        // Prepare data with the right order
        const syncLinkData = {
          title,
          description,
          files: files.map((file, index) => {
            // Remove the actual file object from the data we send
            const { file: actualFile, ...restOfFile } = file;
            return {
              ...restOfFile,
              order: index,
            };
          }),
        };

        if (isEditMode) {
          // Update existing synclinkshare
          response = await axios.put(
            `${API_URL}/synclinkshare/${linkId}`,
            syncLinkData
          );
          setSuccess("Synclink updated successfully");
        } else {
          // Create a new synclinkshare with existing files
          response = await axios.post(`${API_URL}/synclinkshare`, syncLinkData);
          setSuccess("Synclink created successfully");
        }

        const syncLink = response.data;
        let viewUrl;
        let front_end_url = "http://localhost:3000";
        if (syncLink.linkId) {
          viewUrl = `${front_end_url}/views/${syncLink.linkId}`;
        } else if (syncLink.syncLink && syncLink.syncLink.linkId) {
          viewUrl = `${front_end_url}/views/${syncLink.syncLink.linkId}`;
        } else if (response.data.url) {
          // Extract the linkId from the URL and rebuild with the frontend origin
          const linkId = response.data.url.split("/").pop();
          viewUrl = `${front_end_url}/views/${linkId}`;
        } else if (response.data.linkId) {
          // Also handle the case where linkId is directly in the response data
          viewUrl = `${front_end_url}/views/${response.data.linkId}`;
        }

        setCreatedLinkUrl(viewUrl);
      }
    } catch (err) {
      console.error("Error creating/updating Synclink:", err);

      // Update upload status to show errors
      if (showUploadStatus) {
        setUploadProgress((prev) =>
          prev.map((file) => ({
            ...file,
            status: "error",
            error: "Upload failed",
          }))
        );
      }

      // Handle different error formats
      let errorMessage = "Failed to save Synclink. Please try again.";

      if (err.response) {
        console.log("Error response data:", err.response.data);

        if (err.response.data.message) {
          errorMessage = err.response.data.message;
        } else if (err.response.data.error) {
          errorMessage = err.response.data.error;
        } else if (typeof err.response.data === "string") {
          errorMessage = err.response.data;
        }
      } else if (err.message) {
        errorMessage = err.message;
      }

      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // For drag-and-drop reordering
  const handleDragStart = (e, index) => {
    setDraggedItem(index);
    // This helps with the ghost image during drag
    if (e.dataTransfer) {
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/html", e.target.parentNode);
      e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    }
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    if (draggedItem === null) return;
    if (draggedItem === index) return;

    // Reorder the files
    const newFiles = [...files];
    const draggedFile = newFiles[draggedItem];
    newFiles.splice(draggedItem, 1);
    newFiles.splice(index, 0, draggedFile);

    setFiles(newFiles);
    setDraggedItem(index);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
  };

  // Show connection status warning if there are issues
  const showConnectionWarning =
    connectionStatus.cloudinary === false ||
    connectionStatus.database === false;

  return (
    <>
      <DashboardHeader />
      <div className="set-max-width">
        <div className="sync-link-creator">
          <div className="creator-header">
            {!success && !createdLinkUrl && <h3>Create Synclink</h3>}
            <br />
          </div>

          {error && (
            <div className="error-message">
              <p>{error}</p>
            </div>
          )}

          {success && !createdLinkUrl && (
            <div className="success-message">
              <p>{success}</p>
            </div>
          )}

          {/* If we have a created link URL, only show that section */}
          {createdLinkUrl ? (
            <div className="link-created-card">
              <div className="link-created-header">
                <div className="link-created-icon">
                  <FaLink />
                </div>
                <h3>Your Synclink is Ready!</h3>
              </div>

              <div className="link-created-content">
                <p className="link-created-message">
                  Share this link to provide access to your files
                </p>
                <div className="link-input-container">
                  <input
                    type="text"
                    value={createdLinkUrl}
                    readOnly
                    className="link-input"
                    onClick={(e) => e.target.select()}
                  />
                  <button
                    className="copy-link-button"
                    onClick={copyLinkToClipboard}
                    title="Copy link to clipboard"
                  >
                    {linkCopied ? (
                      <>
                        <FaCheck /> Copied!
                      </>
                    ) : (
                      <>
                        <FaCopy /> Copy
                      </>
                    )}
                  </button>
                </div>
              </div>

              <div className="link-created-footer">
                <button
                  className="view-link-button"
                  onClick={() => window.open(createdLinkUrl, "_blank")}
                >
                  <FaEye /> View Synclink
                </button>
                <button
                  className="create-new-link-button"
                  onClick={() => {
                    setCreatedLinkUrl("");
                    window.location.href = `${FRONTEND_URL}/synclinkshare/create`;
                  }}
                >
                  <FaPlus /> Create New Link
                </button>
              </div>
            </div>
          ) : (
            /* Only show the form if we don't have a created link URL */
            <form onSubmit={createSyncLink} className="sync-link-form">
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter a title for your Synclink"
                  disabled={loading}
                  required
                />
              </div>

              {/* <div className="form-group">
              <label htmlFor="description">Description (optional)</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Add a description for your Synclink"
                disabled={loading}
                rows={3}
              />
            </div> */}

              <div className="form-group">
                <label>Upload Files</label>
                <div className="upload-area">
                  <input
                    type="file"
                    id="file-upload"
                    multiple
                    onChange={handleFileSelect}
                    disabled={
                      uploading || loading || files.length >= MAX_FILES_COUNT
                    }
                    className="file-input"
                    ref={fileInputRef}
                  />
                  <label
                    htmlFor="file-upload"
                    className={`upload-label ${
                      files.length >= MAX_FILES_COUNT ? "disabled" : ""
                    }`}
                  >
                    <FaUpload />
                    <span className="upload-text">
                      {uploading
                        ? "Uploading..."
                        : files.length >= MAX_FILES_COUNT
                        ? `Maximum ${MAX_FILES_COUNT} files reached`
                        : "Select Files"}
                    </span>
                  </label>
                  <p className="upload-hint">
                    {files.length >= MAX_FILES_COUNT
                      ? "File limit reached. Remove some files before uploading more."
                      : "Drag and drop files here or click to browse"}
                  </p>
                </div>
              </div>

              {files.length > 0 && (
                <div className="form-group">
                  <label>
                    Attachments{" "}
                    <span className="file-count">({files.length} files)</span>
                  </label>
                  <div className="attachments-container">
                    <p className="reorder-hint">
                      Drag files to reorder or use arrows to change position
                    </p>
                    <div className="file-cards">
                      {files.map((file, index) => {
                        const { icon, color, label } = getFileIconInfo(file);
                        return (
                          <div
                            key={index}
                            className={`file-card ${
                              draggedItem === index ? "dragging" : ""
                            }`}
                            draggable
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={(e) => handleDragOver(e, index)}
                            onDragEnd={handleDragEnd}
                          >
                            <div
                              className="file-card-header"
                              style={{ backgroundColor: color }}
                            >
                              <div className="file-type-icon">
                                {icon}
                                <span className="file-type-label">{label}</span>
                              </div>
                              <div className="file-card-controls">
                                <button
                                  type="button"
                                  className="remove-file-card"
                                  onClick={() => removeFile(index)}
                                  disabled={loading}
                                  title="Remove file"
                                >
                                  <FaTrash />
                                </button>
                              </div>
                            </div>
                            <div className="file-card-content">
                              <div className="file-card-name">
                                {file.originalName || file.name}
                              </div>
                              <div className="file-card-size">
                                {formatFileSize(file.size || file.sizeInBytes)}
                              </div>
                            </div>
                            <div className="file-card-footer">
                              <div className="file-order-controls">
                                <button
                                  type="button"
                                  className={`move-file ${
                                    index === 0 ? "disabled" : ""
                                  }`}
                                  onClick={() => moveFileUp(index)}
                                  disabled={loading || index === 0}
                                  title="Move file up"
                                >
                                  <FaArrowUp />
                                </button>
                                <span className="file-position">
                                  #{index + 1}
                                </span>
                                <button
                                  type="button"
                                  className={`move-file ${
                                    index === files.length - 1 ? "disabled" : ""
                                  }`}
                                  onClick={() => moveFileDown(index)}
                                  disabled={
                                    loading || index === files.length - 1
                                  }
                                  title="Move file down"
                                >
                                  <FaArrowDown />
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}

              <div className="form-actions">
                <button
                  type="submit"
                  className={`submit-button ${loading ? "" : ""}`}
                  disabled={loading || uploading || files.length === 0}
                >
                  {loading ? "Loading..." : "Create Link"}
                </button>
              </div>
            </form>
          )}

          {/* Upload Status Component - show regardless */}
          {showUploadStatus && (
            <FileUploadStatus
              files={uploadProgress}
              onCancel={handleCloseUploadStatus}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SyncLinkShareCreator;
