import React, { useState } from "react";
import "../css/signup.css";
import { Link } from "react-router";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SignIn } from "../redux/apiCalls";

const SignInPage = () => {

  const PASSPORT_URL_BACKEND = process.env.REACT_APP_PASSPORT_URL_BACKEND
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGoogleLogin = () => {
    window.location.href = `${PASSPORT_URL_BACKEND}` + "/auth/google";
  };

  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });

  // desctructure formValues
  const { email, password } = formValue;

  // handles form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return toast.error("Invalid email format.");
    }

    SignIn({ email, password, isVerified: false }, dispatch, navigate);
  };

  // handles form input
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  return (
    <div className="login-container">
      <div className="left-panel">
        <div className="logo-container">
          <div className="logo">
            <span className="logo-icon">✷</span>
          </div>
        </div>
        <div className="welcome-text">
          <h1>
            Hello
            <br />
            Customer! <span className="wave-emoji">👋</span>
          </h1>
          <p>
            Whether you're closing a deal, raising capital, or collaborating on
            sensitive projects, you can have complete control over your
            documents with SynLink.{" "}
          </p>
        </div>
        <div className="copyright">
          © 2025 SyncLinkShare. All rights reserved.
        </div>
      </div>

      <div className="right-panel">
        <div className="login-form-container">
          <h2 className="brand-name">SyncLinkShare.</h2>

          <div className="login-welcome">
            <h1>Welcome Back!</h1>
            <p>
              Don't have an account?{" "}
              <Link to="/signup" className="create-account-link">
                Create a new account now
              </Link>
              , it's FREE! Takes less than a minute.
            </p>
          </div>

          <form className="login-form">
            <div className="form-group">
              <input
                id="email"
                name="email"
                type="email"
                required
                placeholder="Email"
                value={email}
                autoComplete="email"
                onChange={(e) => onInputChange(e)}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                required
                autoComplete="current-password"
                value={password}
                onChange={(e) => onInputChange(e)}
                className="form-control"
              />
            </div>
            <Toaster />
            <button
              type="submit"
              onClick={(e) => handleSubmit(e)}
              className="btn-login"
            >
              Login Now
            </button>
            <button
              type="button"
              onClick={() => handleGoogleLogin()}
              className="btn-google"
            >
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                alt="Google logo"
                className="google-icon"
              />
              Login with Google
            </button>
            {/* <div className="forgot-password">
              <span>Forget password</span>
              <a href="#" className="click-here">
                Click here
              </a>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
