import "../../css/style.css";
import heroImage from "../../assets/hero-image.png";
import logo from "../../assets/hero-image.png";

function Hero() {
  
  return (
    <section className="hero">
      <div className="hero-container">
        <div className="hero-content">
          <div className="hero-logo">
            <img src={logo} alt="Dropbox SynclinkShare" />
          </div>
          <h1>Securely manage, track, and share documents</h1>
          <p>
            Whether you're closing a deal, raising capital, or collaborating on
            sensitive projects, you can have complete control over your
            documents with SynLink.
          </p>
          
          <a href="#" className="btn btn-primary btn-hero">
            Get Started
            <i className="icon-arrow-right"></i>
          </a>
        </div>
        <div className="hero-image">
          <img src={heroImage} alt="SynclinkShare interface" />
        </div>
      </div>
    </section>
  );
}

export default Hero;
