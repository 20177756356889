import '../../css/style.css';
import featureImage1 from '../../assets/feature1.png';
import featureImage2 from '../../assets/feature2.png';
import featureImage3 from '../../assets/feature3.png';

function Features() {
  return (
    <section className="features">
      <div className="features-container">
        <h2>Evolve your business with SynclinkShare features</h2>
        
        <div className="feature-boxes">
          <div className="feature-box">
            <div className="feature-video">
              <img src={featureImage1} alt="Virtual Data Rooms" />
              <button className="play-btn"></button>
            </div>
            <h3>Virtual Data Rooms</h3>
            <p>Explore how Kove uses SynclinkShare to track investor sentiment as they fundraise</p>
            <a href="#" className="feature-link">Learn More</a>
          </div>
          
          <div className="feature-box">
            <div className="feature-video">
              <img src={featureImage2} alt="Document Tracking" />
              <button className="play-btn"></button>
            </div>
            <h3>Document Tracking</h3>
            <p>Wizard Ventures accelerates fundraising by 25% with Dropbox SynclinkShare</p>
            <a href="#" className="feature-link">Learn More</a>
          </div>
          
          <div className="feature-box">
            <div className="feature-video">
              <img src={featureImage3} alt="Group Permissions" />
              <button className="play-btn"></button>
            </div>
            <h3>Group Permissions</h3>
            <p>Optimize your outreach by enabling group permissions and segmenting your audience</p>
            <a href="#" className="feature-link">Learn More</a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;