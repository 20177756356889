import { createSlice } from "@reduxjs/toolkit";

const syncLinkNameCreate = createSlice({
  name: "syncLink",
  initialState: {
    fetchAllRecordsByUserData: [],
    successMsg: "",
    errorMsg: "",
    error: false,
    loading: false,
    updateCounter: 0,
  },
  reducers: {
    syncLinkNameCreate_begin: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    syncLinkNameCreate_success: (state, action) => {
      state.loading = false;
      state.successMsg = "success";
      state.updateCounter += 1;
    },
    syncLinkNameCreate_error: (state, action) => {
      state.loading = false;
      state.errorMsg = action.payload;
    },
    fetchAllRecordsByUser_begin: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    fetchAllRecordsByUser_success: (state, action) => {
      state.loading = false;
      state.successMsg = "success";
      state.fetchAllRecordsByUserData = action.payload;
    },
    fetchAllRecordsByUser_error: (state, action) => {
      state.loading = false;
      state.errorMsg = action.payload;
      state.error = true;
    },
  },
});

export default syncLinkNameCreate.reducer;
export const {
  syncLinkNameCreate_begin,
  syncLinkNameCreate_success,
  syncLinkNameCreate_error,
  fetchAllRecordsByUser_begin,
  fetchAllRecordsByUser_success,
  fetchAllRecordsByUser_error,
} = syncLinkNameCreate.actions;
