import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

const AuthContext = createContext();

const PASSPORT_URL_BACKEND = process.env.REACT_APP_PASSPORT_URL_BACKEND;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // get token

  function getToken() {
    const cookieString = document.cookie;

    const cookies = cookieString.split(";").map((cookie) => cookie.trim());

    const tokenCookie = cookies.find((cookie) => cookie.startsWith("token="));

    if (tokenCookie) {
      return tokenCookie.split("=")[1];
    }
    return null;
  }

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get(
          `${PASSPORT_URL_BACKEND}` + "/api/user",
          {
            headers: {
              Accept: "application/json",
              token: getToken(),
            },
            withCredentials: true,
          }
        );

        if (response.data.isAuthenticated) {
          setUser(response.data.user);
        } else {
          setUser(null);
        }
      } catch (err) {
        setError("Failed to authenticate");
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    checkAuthStatus();
  }, []);

  const logout = async () => {
    try {
      await axios.get("/auth/logout");
      setUser(null);
    } catch (err) {
      setError("Failed to logout");
    }
  };

  return (
    <AuthContext.Provider value={{ user, loading, error, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
