import axios from "axios";
import {
  signup_begin,
  signup_success,
  signup_error,
  signin_begin,
  signin_success,
  signin_error,
} from "./features/authSlice";
import {
  syncLinkNameCreate_begin,
  syncLinkNameCreate_success,
  syncLinkNameCreate_error,
  fetchAllRecordsByUser_begin,
  fetchAllRecordsByUser_success,
  fetchAllRecordsByUser_error,
} from "./features/syncLinkSlice";
import toast, { Toaster } from "react-hot-toast";

const BASE_URL_BACKEND = process.env.REACT_APP_BASE_URL_BACKEND;

export const SignIn = async (data, dispatch, navigate) => {
  dispatch(signin_begin());

  try {
    const send_sign = await axios.post(
      `${BASE_URL_BACKEND}` + "/auth/login",
      data,
      {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    dispatch(signin_success());

    navigate("/Dashboard");
  } catch (error) {
    console.log("errorrr", error.response.data.message);
    toast.error(error.response.data.message);
    dispatch(signin_error());
    return error.response.data.message;
  }
};

export const SignUp = async (data, dispatch, navigate) => {
  dispatch(signup_begin());

  console.log(data);
  try {
    const send_sign = await axios.post(
      `${BASE_URL_BACKEND}` + "/auth/register",
      data,
      {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    dispatch(signup_success());

    toast.success("Check your email to verify your account");
  } catch (error) {
    console.log(error);
    console.log("errorrr", error?.response?.data?.message);
    toast.error(error?.response?.data?.message);
    dispatch(signup_error(error?.response?.data?.message));
    return error?.response?.data?.message;
  }
};

export const SyncLinkCreateName = async (data, dispatch, navigate) => {
  dispatch(syncLinkNameCreate_begin());

  try {
    const send_name = await axios.post(
      `${BASE_URL_BACKEND}` + "/synclink/create",
      data,
      {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    dispatch(syncLinkNameCreate_success());

    toast.success("Name has been added successfully");
  } catch (error) {
    console.log("errorrr", error);
    toast.error(error.response.data.message);
    dispatch(syncLinkNameCreate_error(error.response.data.message));

    return error.response.data.message;
  }
};

export const fetchAllRecordsByUser = async (dispatch) => {
  dispatch(fetchAllRecordsByUser_begin());
  try {
    const fetchAllRecords = await axios.get(
      `${BASE_URL_BACKEND}` + "/synclink/fetchAllRecordsByUser",

      {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    dispatch(fetchAllRecordsByUser_success(fetchAllRecords["data"]["data"]));
  } catch (error) {
    dispatch(fetchAllRecordsByUser_error());
    console.log(error);
    return;
  }
};
