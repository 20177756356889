import '../../css/style.css';
import logo from '../../assets/logo.svg';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <img src={logo} alt="Dropbox SynclinkShare" />
          <span>SynclinkShare is now part of Dropbox</span>
        </div>
        
        <div className="footer-links">
          <div className="footer-column">
            <h4>SynclinkShare</h4>
            <ul>
              <li><a href="#">How it works</a></li>
              <li><a href="#">Integrations</a></li>
              <li><a href="#">Help Center</a></li>
              <li><a href="#">Pricing</a></li>
            </ul>
          </div>
          
          <div className="footer-column">
            <h4>FEATURES</h4>
            <ul>
              <li><a href="#">Secure Sharing</a></li>
              <li><a href="#">Document Analytics</a></li>
              <li><a href="#">Video Analytics</a></li>
              <li><a href="#">Dynamic Watermarking</a></li>
              <li><a href="#">Data Rooms</a></li>
              <li><a href="#">eSignature</a></li>
              <li><a href="#">Non Disclosure Agreement</a></li>
            </ul>
          </div>
          
          <div className="footer-column">
            <h4>SOLUTIONS</h4>
            <ul>
              <li><a href="#">Founder Fundraising</a></li>
              <li><a href="#">Emerging Funds</a></li>
              <li><a href="#">Investment Management</a></li>
              <li><a href="#">Media Sales</a></li>
              <li><a href="#">Technology Sales</a></li>
              <li><a href="#">Professional Services</a></li>
              <li><a href="#">Non-Profit</a></li>
            </ul>
          </div>
          
          <div className="footer-column">
            <h4>RESOURCES</h4>
            <ul>
              <li><a href="#">NDA Template</a></li>
              <li><a href="#">Fundraising Playbook</a></li>
              <li><a href="#">Data-Driven Selling</a></li>
              <li><a href="#">Pitch Deck Template</a></li>
              <li><a href="#">Board Meeting Template</a></li>
              <li><a href="#">Investor Report Template</a></li>
            </ul>
          </div>
          
          <div className="footer-column">
            <h4>ABOUT US</h4>
            <ul>
              <li><a href="#">Impressum</a></li>
              <li><a href="#">Careers</a></li>
              <li><a href="#">Security</a></li>
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Copyright and IP Policy</a></li>
              <li><a href="#">Terms of Service</a></li>
              <li><a href="#">Cookies & CCPA preferences</a></li>
            </ul>
          </div>
        </div>
        
        <div className="footer-bottom">
          <p>© 2025 SynclinkShare</p>
          <div className="social-links">
            <a href="#"><i className="icon-youtube"></i></a>
            <a href="#"><i className="icon-linkedin"></i></a>
            <a href="#"><i className="icon-twitter"></i></a>
            <a href="#"><i className="icon-facebook"></i></a>
            <a href="#"><i className="icon-instagram"></i></a>
            <a href="#"><i className="icon-tiktok"></i></a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;