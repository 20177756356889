import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router";
import logo from "../../assets/logo.svg";
import { useAuth } from "../../context/AuthContext";
import "../../css/style.css";

function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const headerRef = useRef(null);
  const { user } = useAuth();

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);

    // Prevent scrolling when mobile menu is open
    if (!mobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        headerRef.current &&
        !headerRef.current.contains(event.target) &&
        mobileMenuOpen
      ) {
        setMobileMenuOpen(false);
        document.body.style.overflow = "auto";
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileMenuOpen]);

  // Close mobile menu on resize to desktop
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 940 && mobileMenuOpen) {
        setMobileMenuOpen(false);
        document.body.style.overflow = "auto";
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobileMenuOpen]);

  return (
    <header
      className={`header ${mobileMenuOpen ? "menu-open" : ""}`}
      ref={headerRef}
    >
      <div className="header-container">
        {/* Top bar with logo and toggle button */}
        <div className="header-top-bar">
          <div className="logo">
            <img src={logo} alt="Dropbox SynclinkShare" />
            <span className="logo-text">SynclinkShare</span>
          </div>

          <button
            className="mobile-menu-toggle"
            onClick={toggleMobileMenu}
            aria-label="Toggle menu"
          >
            <span
              className={`hamburger ${mobileMenuOpen ? "open" : ""}`}
            ></span>
          </button>
        </div>

        {/* Navigation section */}
        <div className={`nav-section ${mobileMenuOpen ? "open" : ""}`}>
          <nav className="main-nav">
            <ul>
              <li>
                <button onClick={() => console.log("Product clicked")}>
                  Product
                </button>
              </li>
              <li>
                <button onClick={() => console.log("Solutions clicked")}>
                  Solutions
                </button>
              </li>
            </ul>
          </nav>

          {/* Actions - conditionally show based on authentication */}
          <div className="actions">
            {!user ? (
              <>
                <Link to="/signin" className="btn btn-secondary">
                  Login
                </Link>
                <Link
                  to="signup"
                  className="btn btn-primary"
                  onClick={() => console.log("Get Started clicked")}
                >
                  Sign Up
                </Link>
              </>
            ) : (
              <Link to="/dashboard" className="btn btn-primary">
                Dashboard
              </Link>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
