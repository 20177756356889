import React, { useState, useRef, useEffect } from "react";
import "../../css/dashboard/DashboardHeader.css";
import logo from "../../assets/mark.svg";
import { Link } from "react-router";
import { logout, useAuth } from "../../context/AuthContext";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;


const DashboardHeader = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const { user, logout } = useAuth();

  const clearAllCookies = () => {
    console.log("Before clearing:", document.cookie);
    document.cookie.split(";").forEach((cookie) => {
      const name = cookie.split("=")[0].trim();
      // Try with root path
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      // Also try with no path specified
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
      // If you have specific paths in your app
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/synclinkshare;`;
    });
    console.log("After clearing:", document.cookie);
  };

  // Client-side code to call server
  const handleSignOut = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(`${API_URL}/synclinkshare/user/me`, {
        headers: {
          "Content-Type": "multipart/form-data",
          // token: getToken(),
        },
        withCredentials: true,
      });

      // Call server logout endpoint
      await fetch(`${API_URL}/auth/logout`, {
        method: "POST",
        withCredentials: true,
      });

      // Still try to clear cookies client-side as a backup
      clearAllCookies();

      window.location.href = "/";
    } catch (error) {
      console.error("Error signing out:", error);
      // Still redirect even if there was an error
      window.location.href = "/";
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target) &&
        !event.target.classList.contains("dashboard-mobile-menu-button")
      ) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, mobileMenuRef]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="dashboard-header">
      <div className="dashboard-header-container">
        <div className="dashboard-header-left">
          <div className="dashboard-logo">
            <Link to="/">
              <img src={logo} alt="SynclinkShare" />
            </Link>
          </div>
        </div>

        {/* Center Mobile Menu Button */}
        <div className="dashboard-mobile-menu-container">
          <button
            className="dashboard-mobile-menu-button"
            onClick={toggleMobileMenu}
          >
            <span className="dashboard-mobile-menu-icon"></span>
          </button>
        </div>

        {/* Navigation */}
        <nav
          className={`dashboard-nav ${isMobileMenuOpen ? "open" : ""}`}
          ref={mobileMenuRef}
        >
          <ul>
            <Link to="/dashboard" className="dashboard-nav-item">
              Dashboard
            </Link>
          </ul>
        </nav>

        <div className="dashboard-header-right">
          <div className="dashboard-notifications">
            <button className="dashboard-notification-button">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V12.5C4.00003 13.0523 3.55231 13.5 3.00003 13.5C2.44774 13.5 2.00003 13.9477 2.00003 14.5C2.00003 15.0523 2.44774 15.5 3.00003 15.5H17C17.5523 15.5 18 15.0523 18 14.5C18 13.9477 17.5523 13.5 17 13.5C16.4477 13.5 16 13.0523 16 12.5V8C16 4.68629 13.3137 2 10 2Z"
                  stroke="white"
                  strokeWidth="1.5"
                />
                <path
                  d="M10 18C11.1046 18 12 17.1046 12 16H8C8 17.1046 8.89543 18 10 18Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>

          <div className="dashboard-profile-container" ref={dropdownRef}>
            <button
              className="dashboard-profile-button"
              onClick={toggleDropdown}
            >
              <img
                src="https://i.pravatar.cc/150?img=12"
                alt="Profile"
                className="dashboard-profile-image"
              />
            </button>

            {isDropdownOpen && (
              <div className="dashboard-profile-dropdown">
                <ul>
                  <li>
                    <a href="#" onClick={handleSignOut}>
                      Sign Out
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader;
