import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import {
  FaFilePdf,
  FaFilePowerpoint,
  FaFileWord,
  FaFileImage,
  FaFileAlt,
  FaSpinner,
  FaChevronLeft,
  FaChevronRight,
  FaDownload,
} from "react-icons/fa";
import { Document, Page, pdfjs } from "react-pdf";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "../css/dashboard/SyncLinkShareViewer.css";
import "pdfjs-dist/webpack";

// Initialize PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const SyncLinkShareViewer = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

  const { linkId } = useParams("");
  const [syncLinkShare, setSyncLinkShare] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [pdfPageCounts, setPdfPageCounts] = useState({});
  const [docPageCounts, setDocPageCounts] = useState({}); // Add state for Word document page counts
  const [pptPageCounts, setPptPageCounts] = useState({}); // Add state for PowerPoint slide counts
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [pdfScale, setPdfScale] = useState(1);
  const mainContentRef = useRef(null);
  const [wordViewerFailed, setWordViewerFailed] = useState(false);
  const [pptViewerFailed, setPptViewerFailed] = useState(false);

  // Function to calculate optimal content size based on viewport
  const calculateOptimalSize = () => {
    // Get the viewport dimensions
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate available width based on sidebar state
    const sidebarWidth = sidebarOpen ? 180 : 50; // Reduced from 220px to 180px
    const availableWidth = viewportWidth - sidebarWidth - 40; // 40px for padding/margins

    // Calculate available height (account for header/footer if any)
    const headerFooterHeight = 120; // Adjust this based on your UI
    const availableHeight = viewportHeight - headerFooterHeight;

    // Calculate optimal width (90% of available width, minimum 800px)
    const optimalWidth = Math.max(availableWidth * 0.9, 800);

    // Calculate optimal height (85% of available height, minimum 600px)
    const optimalHeight = Math.max(availableHeight * 0.85, 600);

    return {
      width: optimalWidth,
      height: optimalHeight,
    };
  };

  // Function to estimate document page counts for Word and PowerPoint
  const estimateDocumentPageCount = async (file) => {
    // Skip if we already have the count
    if (
      (isPdf(file) && pdfPageCounts[file.fileUrl]) ||
      (isWordDocument(file) && docPageCounts[file.fileUrl]) ||
      (isPowerPoint(file) && pptPageCounts[file.fileUrl])
    ) {
      return;
    }

    try {
      // For PDFs, we're already handling this with onDocumentLoadSuccess
      if (isPdf(file)) {
        return;
      }

      // For Word and PowerPoint, make a HEAD request to get file size
      const response = await axios.head(file.fileUrl);
      const contentLength = response.headers["content-length"];

      if (contentLength) {
        const fileSizeKB = contentLength / 1024;
        let estimatedPages;

        if (isWordDocument(file)) {
          // Rough estimate: ~80KB per page for Word docs (varies widely)
          estimatedPages = Math.max(1, Math.round(fileSizeKB / 80));
          setDocPageCounts((prev) => ({
            ...prev,
            [file.fileUrl]: estimatedPages,
          }));
        } else if (isPowerPoint(file)) {
          // Rough estimate: ~200KB per slide for PowerPoint (varies widely)
          estimatedPages = Math.max(1, Math.round(fileSizeKB / 200));
          setPptPageCounts((prev) => ({
            ...prev,
            [file.fileUrl]: estimatedPages,
          }));
        }
      }
    } catch (error) {
      console.error("Error estimating page count:", error);
    }
  };

  // Fetch synclinkshare data
  useEffect(() => {
    const fetchSyncLinkShareLink = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${API_URL}/synclinkshare/${linkId}`);
        const data = response.data;

        // Sort files by order if available
        if (data.files && Array.isArray(data.files)) {
          data.files.sort((a, b) => (a.order || 0) - (b.order || 0));
        }

        setSyncLinkShare(data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching synclinkshare:", err);
        setError(
          "Failed to load content. The link may have expired or been removed."
        );
      } finally {
        setLoading(false);
      }
    };

    if (linkId) {
      fetchSyncLinkShareLink();
    }
  }, [linkId]);

  // Effect to estimate page counts when files load
  useEffect(() => {
    if (syncLinkShare?.files) {
      syncLinkShare.files.forEach((file) => {
        estimateDocumentPageCount(file);
      });
    }
  }, [syncLinkShare]);

  // Reset viewer failed states when changing files
  useEffect(() => {
    setWordViewerFailed(false);
    setPptViewerFailed(false);
  }, [selectedFileIndex]);

  // Function to get file icon based on file type
  const getFileIcon = (file) => {
    const fileType = file.fileType || "";
    const fileName = file.originalName || "";

    if (fileType.includes("pdf") || fileName.toLowerCase().endsWith(".pdf")) {
      return <FaFilePdf />;
    }

    if (
      fileType.includes("powerpoint") ||
      fileType.includes("presentation") ||
      fileName.toLowerCase().match(/\.(ppt|pptx)$/)
    ) {
      return <FaFilePowerpoint />;
    }

    if (
      fileType.includes("word") ||
      fileType.includes("document") ||
      fileName.toLowerCase().match(/\.(doc|docx)$/)
    ) {
      return <FaFileWord />;
    }

    if (
      fileType.includes("image") ||
      fileName.toLowerCase().match(/\.(jpg|jpeg|png|gif|bmp|svg|webp)$/)
    ) {
      return <FaFileImage />;
    }

    return <FaFileAlt />;
  };

  // Function to get file color based on file type
  const getFileColor = (file) => {
    const fileType = file.fileType || "";
    const fileName = file.originalName || "";

    if (fileType.includes("pdf") || fileName.toLowerCase().endsWith(".pdf")) {
      return "#e74c3c";
    }

    if (
      fileType.includes("powerpoint") ||
      fileType.includes("presentation") ||
      fileName.toLowerCase().match(/\.(ppt|pptx)$/)
    ) {
      return "#f39c12";
    }

    if (
      fileType.includes("word") ||
      fileType.includes("document") ||
      fileName.toLowerCase().match(/\.(doc|docx)$/)
    ) {
      return "#3498db";
    }

    if (
      fileType.includes("image") ||
      fileName.toLowerCase().match(/\.(jpg|jpeg|png|gif|bmp|svg|webp)$/)
    ) {
      return "#27ae60";
    }

    return "#95a5a6";
  };

  // Function to check if file is a PDF
  const isPdf = (file) => {
    return (
      file.fileType?.includes("pdf") ||
      file.originalName?.toLowerCase().endsWith(".pdf")
    );
  };

  // Function to check if file is an image
  const isImage = (file) => {
    return (
      file.fileType?.includes("image") ||
      file.originalName
        ?.toLowerCase()
        .match(/\.(jpg|jpeg|png|gif|bmp|svg|webp)$/)
    );
  };

  // Function to check if file is a Word document
  const isWordDocument = (file) => {
    return (
      file.fileType?.includes("word") ||
      file.fileType?.includes("document") ||
      file.originalName?.toLowerCase().match(/\.(doc|docx)$/)
    );
  };

  // Function to check if file is a PowerPoint document
  const isPowerPoint = (file) => {
    return (
      file.fileType?.includes("powerpoint") ||
      file.fileType?.includes("presentation") ||
      file.originalName?.toLowerCase().match(/\.(ppt|pptx)$/)
    );
  };

  // Function to check if file is a document (PDF, Word, PowerPoint)
  const isDocument = (file) => {
    return (
      file.fileType?.includes("pdf") ||
      file.fileType?.includes("word") ||
      file.fileType?.includes("document") ||
      file.fileType?.includes("powerpoint") ||
      file.fileType?.includes("presentation") ||
      file.originalName?.toLowerCase().match(/\.(pdf|doc|docx|ppt|pptx)$/)
    );
  };

  // Function to handle document loading for page count
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);

    // Store page count for the current file
    if (syncLinkShare?.files && syncLinkShare.files[selectedFileIndex]) {
      const currentFile = syncLinkShare.files[selectedFileIndex];
      setPdfPageCounts((prev) => ({
        ...prev,
        [currentFile.fileUrl]: numPages,
      }));
    }
  };

  // Function to handle page change
  const changePage = (offset) => {
    setCurrentPage((prevPage) => {
      const newPage = prevPage + offset;
      return newPage > 0 && newPage <= numPages ? newPage : prevPage;
    });
  };

  // Scale adjustment for PDF
  const adjustScale = (delta) => {
    setPdfScale((prevScale) => {
      const newScale = Math.max(0.5, Math.min(2.0, prevScale + delta));
      return newScale;
    });
  };

  // Toggle sidebar
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Handle file selection
  const selectFile = (index) => {
    setSelectedFileIndex(index);
    setCurrentPage(1); // Reset to first page when changing files

    // Scroll main content to top
    if (mainContentRef.current) {
      mainContentRef.current.scrollTop = 0;
    }
  };

  // Render PDF viewer
  const renderPdfViewer = (selectedFile) => {
    const { width } = calculateOptimalSize();

    return (
      <div className="pdf-viewer-container">
        {/* Add PDF viewer header similar to Word and PowerPoint */}
        <div className="pdf-viewer-header">
          <h3>{selectedFile.originalName}</h3>
          <a
            href={selectedFile.fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="download-button"
            title="Download original PDF"
          >
            <FaDownload /> Download
          </a>
        </div>

        <div className="pdf-controls">
          <button
            onClick={() => changePage(-1)}
            disabled={currentPage <= 1}
            className="page-button"
          >
            <FaChevronLeft />
          </button>
          <span className="page-info">
            Page {currentPage} of {numPages}
          </span>
          <button
            onClick={() => changePage(1)}
            disabled={currentPage >= numPages}
            className="page-button"
          >
            <FaChevronRight />
          </button>
          <div className="scale-controls">
            <button
              onClick={() => adjustScale(-0.1)}
              className="scale-button"
              disabled={pdfScale <= 0.5}
            >
              -
            </button>
            <span className="scale-value">{Math.round(pdfScale * 100)}%</span>
            <button
              onClick={() => adjustScale(0.1)}
              className="scale-button"
              disabled={pdfScale >= 2.0}
            >
              +
            </button>
          </div>
        </div>

        <div className="pdf-document-container horizontal-scroll">
          <Document
            file={selectedFile.fileUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <div className="loading-indicator">
                <FaSpinner className="spinner" /> Loading PDF...
              </div>
            }
            error={
              <div className="error-message">
                Error loading PDF. Try refreshing the page.
              </div>
            }
            options={{
              removePageBorders: true,
              disablePageLabels: true,
            }}
          >
            <div className="pdf-pages-row">
              {Array.from(new Array(numPages), (el, index) => (
                <div key={`page_${index + 1}`} className="pdf-page-wrapper">
                  <div className="pdf-page-number"></div>
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    width={width * 0.8 * pdfScale}
                    className="pdf-page"
                    hidePageLabels={true}
                    renderMode="canvas"
                  />
                </div>
              ))}
            </div>
          </Document>
        </div>
      </div>
    );
  };

  // Render image viewer with larger display
  const renderImageViewer = (selectedFile) => {
    return (
      <div className="image-container">
        <img
          src={selectedFile.fileUrl}
          alt={selectedFile.originalName || "Image"}
          className="displayed-image"
        />
      </div>
    );
  };

  // Render Word document viewer
  const renderWordViewer = (selectedFile) => {
    // If viewer has failed, show download prompt
    if (wordViewerFailed) {
      return (
        <div className="word-container">
          <div className="word-download-prompt">
            <div className="word-icon">
              <FaFileWord />
            </div>
            <h3>{selectedFile.originalName}</h3>
            <p>
              This document couldn't be previewed. Please download it to view.
            </p>
            <a
              href={selectedFile.fileUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="download-button"
            >
              <FaDownload /> Download Document
            </a>
          </div>
        </div>
      );
    }

    const encodedUrl = encodeURIComponent(selectedFile.fileUrl);
    const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${encodedUrl}&embedded=true`;

    return (
      <div className="word-container">
        <div className="word-viewer-header">
          <h3>{selectedFile.originalName}</h3>
          <a
            href={selectedFile.fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="download-button"
            title="Download original document"
          >
            <FaDownload /> Download
          </a>
        </div>
        {docPageCounts[selectedFile.fileUrl] && (
          <div className="doc-navigation">
            <span className="page-count">
              {docPageCounts[selectedFile.fileUrl]} pages total
            </span>
          </div>
        )}
        <div className="word-iframe-container">
          <div className="word-loading-overlay">
            <FaSpinner className="spinner" />
            <p>Loading document viewer...</p>
          </div>
          <iframe
            src={googleDocsViewerUrl}
            className="word-iframe"
            title="Word Document Viewer"
            onLoad={(e) => {
              try {
                // Find the loading overlay within the closest parent with the appropriate class
                const container = e.target.closest(".word-container");
                if (container) {
                  const overlay = container.querySelector(
                    ".word-loading-overlay"
                  );
                  if (overlay) {
                    overlay.style.display = "none";
                  }
                }

                // Check if the frame loaded with an error page (Google Docs shows an error page)
                // This is a bit of a hack because we can't directly access iframe content due to CORS
                // We check for the load event but also set a timer to check if viewer works
                setTimeout(() => {
                  // After 5 seconds, if loading indicator is still visible, assume viewer failed
                  const overlay = container.querySelector(
                    ".word-loading-overlay"
                  );
                  if (overlay && overlay.style.display !== "none") {
                    setWordViewerFailed(true);
                  }
                }, 5000);
              } catch (err) {
                console.error("Error handling iframe load:", err);
                setWordViewerFailed(true);
              }
            }}
            onError={() => setWordViewerFailed(true)}
          />
        </div>
      </div>
    );
  };

  // Render PowerPoint document viewer
  const renderPowerPointViewer = (selectedFile) => {
    // If viewer has failed, show download prompt
    if (pptViewerFailed) {
      return (
        <div className="ppt-container">
          <div className="ppt-download-prompt">
            <div className="ppt-icon">
              <FaFilePowerpoint />
            </div>
            <h3>{selectedFile.originalName}</h3>
            <p>
              This presentation couldn't be previewed. Please download it to
              view.
            </p>
            <a
              href={selectedFile.fileUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="download-button"
            >
              <FaDownload /> Download Presentation
            </a>
          </div>
        </div>
      );
    }

    const encodedUrl = encodeURIComponent(selectedFile.fileUrl);

    // Try Office 365 viewer as primary option
    const office365ViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`;

    return (
      <div className="ppt-container">
        <div className="ppt-viewer-header">
          <h3>{selectedFile.originalName}</h3>
          <a
            href={selectedFile.fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="download-button"
            title="Download original presentation"
          >
            <FaDownload /> Download
          </a>
        </div>
        {pptPageCounts[selectedFile.fileUrl] && (
          <div className="ppt-navigation">
            <span className="slide-count">
              {pptPageCounts[selectedFile.fileUrl]} slides total
            </span>
          </div>
        )}
        <div className="ppt-iframe-container">
          <div className="ppt-loading-overlay">
            <FaSpinner className="spinner" />
            <p>Loading presentation viewer...</p>
          </div>
          <iframe
            src={office365ViewerUrl}
            className="ppt-iframe"
            title="PowerPoint Viewer"
            onLoad={(e) => {
              try {
                // Find the loading overlay within the closest parent
                const container = e.target.closest(".ppt-container");
                if (container) {
                  const overlay = container.querySelector(
                    ".ppt-loading-overlay"
                  );
                  if (overlay) {
                    overlay.style.display = "none";
                  }
                }

                // Set a timer to check if viewer works
                setTimeout(() => {
                  // After 5 seconds, if loading indicator is still visible, assume viewer failed
                  const overlay = container.querySelector(
                    ".ppt-loading-overlay"
                  );
                  if (overlay && overlay.style.display !== "none") {
                    setPptViewerFailed(true);
                  }
                }, 5000);
              } catch (err) {
                console.error("Error handling iframe load:", err);
                setPptViewerFailed(true);
              }
            }}
            onError={() => setPptViewerFailed(true)}
          />
        </div>
      </div>
    );
  };

  // Render document viewer for other types
  const renderDocumentViewer = (selectedFile) => {
    return (
      <div className="doc-container">
        <DocViewer
          documents={[
            {
              uri: selectedFile.fileUrl,
              fileName: selectedFile.originalName,
            },
          ]}
          pluginRenderers={DocViewerRenderers}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: false,
            },
          }}
        />
      </div>
    );
  };

  // Render the main content based on file type
  const renderMainContent = () => {
    if (
      !syncLinkShare ||
      !syncLinkShare.files ||
      syncLinkShare.files.length === 0
    ) {
      return <div className="no-files">No files to display</div>;
    }

    const selectedFile = syncLinkShare.files[selectedFileIndex];

    if (isPdf(selectedFile)) {
      return renderPdfViewer(selectedFile);
    } else if (isImage(selectedFile)) {
      return renderImageViewer(selectedFile);
    } else if (isWordDocument(selectedFile)) {
      return renderWordViewer(selectedFile);
    } else if (isPowerPoint(selectedFile)) {
      return renderPowerPointViewer(selectedFile);
    } else if (isDocument(selectedFile)) {
      return renderDocumentViewer(selectedFile);
    } else {
      // For unsupported file types
      return (
        <div className="unsupported-file">
          <div
            className="file-icon-large"
            style={{ color: getFileColor(selectedFile) }}
          >
            {getFileIcon(selectedFile)}
          </div>
          <h3>{selectedFile.originalName}</h3>
          <p>Preview not available for this file type</p>
        </div>
      );
    }
  };

  // Truncate filename if too long
  const truncateFileName = (name, maxLength = 18) => {
    if (!name) return "Unnamed file";
    if (name.length <= maxLength) return name;

    const extension = name.includes(".") ? name.split(".").pop() : "";
    const baseName = name.includes(".")
      ? name.substring(0, name.lastIndexOf("."))
      : name;

    // Keep the extension and truncate the base name
    const truncatedBase = baseName.substring(
      0,
      maxLength - extension.length - 3
    );
    return `${truncatedBase}...${extension ? "." + extension : ""}`;
  };

  if (loading) {
    return (
      <div className="synclinkshare-link-view loading">
        <FaSpinner className="spinner" />
        <p>Loading content...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="synclinkshare-link-view error">
        <div className="error-container">
          <h2>Error</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (!syncLinkShare) {
    return (
      <div className="synclinkshare-link-view not-found">
        <div className="not-found-container">
          <h2>Content Not Found</h2>
          <p>The requested content could not be found or has been removed.</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`synclinkshare-link-view ${
        sidebarOpen ? "sidebar-open" : "sidebar-closed"
      }`}
    >
      {/* Sidebar Toggle Button */}
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        {sidebarOpen ? <FaChevronLeft /> : <FaChevronRight />}
      </button>

      {/* Sidebar with file thumbnails */}
      <div className={`sidebar ${sidebarOpen ? "open" : "closed"}`}>
        <div className="sidebar-header">
          <h3 className="sidebar-title">{syncLinkShare.title}</h3>
          <div className="file-count">{syncLinkShare.files.length} files</div>
        </div>

        <div className="file-thumbnails">
          {syncLinkShare.files.map((file, index) => (
            <div
              key={index}
              className={`file-thumbnail ${
                selectedFileIndex === index ? "selected" : ""
              }`}
              onClick={() => selectFile(index)}
            >
              <div
                className="thumbnail-icon-container"
                style={{ backgroundColor: getFileColor(file) }}
              >
                <div className="thumbnail-icon">{getFileIcon(file)}</div>

                {/* Show page count for all document types */}
                {isPdf(file) && pdfPageCounts[file.fileUrl] && (
                  <div className="page-count-badge">
                    {pdfPageCounts[file.fileUrl]} pages
                  </div>
                )}
                {isWordDocument(file) && docPageCounts[file.fileUrl] && (
                  <div className="page-count-badge">
                    {docPageCounts[file.fileUrl]} pages
                  </div>
                )}
                {isPowerPoint(file) && pptPageCounts[file.fileUrl] && (
                  <div className="page-count-badge">
                    {pptPageCounts[file.fileUrl]} slides
                  </div>
                )}
              </div>

              <div className="thumbnail-filename">
                {truncateFileName(file.originalName)}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Main content area */}
      <div
        className="main-content"
        ref={mainContentRef}
        style={{
          width: sidebarOpen ? "calc(100% - 180px)" : "calc(100% - 50px)", // Changed from 220px to 180px
        }}
      >
        {renderMainContent()}
      </div>

      {/* Footer */}
      <div className="view-footer">
        <div className="footer-content">
          <span className="synclink-brand">
            <span className="synclink-brand">
              <Link
                to={`${FRONTEND_URL}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Create yours @ synclinkshare.com
              </Link>
            </span>
          </span>
          {syncLinkShare.description && (
            <span className="description-text">
              {syncLinkShare.description}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SyncLinkShareViewer;
