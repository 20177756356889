import '../../css/style.css';

function Platform() {
  return (
    <section className="platform">
      <div className="platform-container">
        <h2>The all in one platform for deal management</h2>
        <p>
          SynclinkShare is built to allow companies, founders and financial teams to swiftly navigate the
          dealmaking process.
        </p>
        
        <div className="feature-cards">
          <div className="feature-card">
            <h3>Client Portals</h3>
            <p>Securely share documents through a custom branded client portal</p>
          </div>
          <div className="feature-card">
            <h3>Mergers & Acquisitions</h3>
            <p>Stay in control of deal documents</p>
          </div>
          <div className="feature-card">
            <h3>Due Diligence</h3>
            <p>Leverage SynclinkShare VDRs for more efficient deals</p>
          </div>
          <div className="feature-card">
            <h3>Fundraising</h3>
            <p>Keep your deck updated — even after it's shared</p>
          </div>
          <div className="feature-card">
            <h3>Board Meetings</h3>
            <p>Organize board materials with a single click</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Platform;