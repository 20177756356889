import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    all_tours: [],
    successMsg: "",
    errorMsg: "",
    error: false,
    loading: false,
    example: "this is an example",
  },
  reducers: {
    signup_begin: (state) => {
      state.loading = true;
      state.error = false;
    },
    signup_success: (state, action) => {
      state.loading = false;
      state.successMsg = "success";
    },
    signup_error: (state, action) => {
      state.loading = false;
      state.errorMsg = action.payload;
    },
    signin_begin: (state) => {
      state.loading = true;
      state.error = false;
    },
    signin_success: (state, action) => {
      state.loading = false;
      state.all_tours = action.payload;
    },
    signin_error: (state) => {
      state.loading = false;
      state.error = true;
    }
  },
});

export default authSlice.reducer;
export const {
  signup_begin,
  signup_success,
  signup_error,
  signin_begin,
  signin_success,
  signin_error,
} = authSlice.actions;
