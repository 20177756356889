import '../../css/style.css';
import vdrImage from '../../assets/vdr-image.png';

function VirtualDataRooms() {
  return (
    <section className="vdr">
      <div className="vdr-container">
        <div className="vdr-content">
          <div className="vdr-icon">
            <i className="icon-dataroom"></i>
          </div>
          <h2>Designed to bring pitching and dealmaking to the next level.</h2>
          <p>
            SynclinkShare links require no log-in and can be updated and tracked with no disruption to the viewer.
          </p>
        </div>
        <div className="vdr-image">
          <img src={vdrImage} alt="Virtual data room interface" />
        </div>
      </div>
    </section>
  );
}

export default VirtualDataRooms;