import React from "react";
import DashboardHeader from "../components/dashboard/DashboardHeader";
import DashboardHero from "../components/dashboard/DashboardHero";
import Footer from "../components/landingpage/Footer";
import DashboardMain from "../components/dashboard/DashboardMain";

const Dashboard = () => {
  return (
    <div>
      <DashboardHeader />
      
      <DashboardMain />
      <Footer />
    </div>
  );
};

export default Dashboard;
