import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../css/signup.css";
import { Link } from "react-router";
import toast, { Toaster } from "react-hot-toast";
import { SignUp } from "../redux/apiCalls";


const SignUpPage = () => {

  const PASSPORT_URL_BACKEND = process.env.REACT_APP_PASSPORT_URL_BACKEND
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGoogleLogin = () => {
    window.location.href = `${PASSPORT_URL_BACKEND}` + "/auth/google";
  };

  const [formValue, setFormValue] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const { username, email, password, confirmPassword } = formValue;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (username.trim().length < 6) {
      return toast.error("Username must be at least 3 characters long.");
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return toast.error("Invalid email format.");
    }

    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*.]{6,}$/;

    if (!passwordRegex.test(password)) {
      return toast.error(
        "Password must be at least 9 characters long and include a number."
      );
    }

    if (password !== confirmPassword) {
      return toast.error("Passwords do not match.");
    }

    SignUp(
      { username, email, password, isVerified: false },
      dispatch,
      navigate
    );
  };

  // handles form input
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  return (
    <div className="login-container">
      <div className="left-panel">
        <div className="logo-container">
          <div className="logo">
            <span className="logo-icon">✷</span>
          </div>
        </div>
        <div className="welcome-text">
          <h1>
            Hello
            <br />
            Customer! <span className="wave-emoji">👋</span>
          </h1>
          <p>
            Whether you're closing a deal, raising capital, or collaborating on
            sensitive projects, you can have complete control over your
            documents with SynLink.{" "}
          </p>
        </div>
        <div className="copyright">
          © 2025 SyncLinkShare. All rights reserved.
        </div>
      </div>

      <div className="right-panel">
        <div className="login-form-container">
          <h2 className="brand-name">SyncLinkShare.</h2>

          <div className="login-welcome">
            <h1>Welcome Back!</h1>
            <p>
              Already have an account ? <br />
              <Link to="/signin" className="create-account-link">
                Signin and create your links
              </Link>
            </p>
          </div>

          <Toaster />

          <form className="login-form" action="#">
            <div className="form-group">
              <input
                id="username"
                name="username"
                type="username"
                placeholder="Username"
                required
                autoComplete="username"
                value={username}
                onChange={(e) => onInputChange(e)}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                required
                value={email}
                autoComplete="email"
                onChange={(e) => onInputChange(e)}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                required
                autoComplete="current-password"
                value={password}
                onChange={(e) => onInputChange(e)}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <input
                id="Confirmpassword"
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password "
                value={confirmPassword}
                onChange={(e) => onInputChange(e)}
                required
                className="form-control"
              />
            </div>

            <button
              onClick={(e) => handleSubmit(e)}
              type="submit"
              className="btn-login"
            >
              Sign Up
            </button>

            <button
              type="button"
              onClick={handleGoogleLogin}
              className="btn-google"
            >
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                alt="Google logo"
                className="google-icon"
              />
              Login with Google
            </button>

            {/* <div className="forgot-password">
              <span>Forget password</span>
              <a href="#" className="click-here">
                Click here
              </a>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
