import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
  Navigate,
} from "react-router-dom";
import "./App.css";
import LandingPage from "./pages/LandingPage";
import SignUpPage from "./pages/SignUpPage";
import SignInPage from "./pages/SignInPage";
import { AuthProvider, useAuth } from "./context/AuthContext";
import Dashboard from "./pages/Dashboard";
import SyncLinkShareCreator from "./pages/SyncLinkShareCreator";
import SyncLinkShareViewer from "./pages/SyncLinkShareViewer";
import SyncLinkShareUpdate from "./pages/SyncLinkShareUpdate";

// Protected route component
const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) return <div></div>;

  if (!user) {
    return <Navigate to="/" />;
  }

  return children;
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route
            path="/dashboard"
            element={<ProtectedRoute>{<Dashboard />}</ProtectedRoute>}
          />
          <Route
            path="/synclinkshare/update/:linkId"
            element={<ProtectedRoute>{<SyncLinkShareUpdate />}</ProtectedRoute>}
          />
          <Route
            path="/synclinkshare/create"
            element={
              <ProtectedRoute>{<SyncLinkShareCreator />}</ProtectedRoute>
            }
          />
          <Route path="/" element={<LandingPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route
            path="/synclinkshare/view/:linkId"
            element={<SyncLinkShareViewer />}
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
