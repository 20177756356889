import '../../css/style.css';
import airtableLogo from '../../assets/airtable.svg';
import accelLogo from '../../assets/accel.svg';
import gartnerLogo from '../../assets/gartner.svg';
import greycroft from '../../assets/greycroft.svg';
import five00Logo from '../../assets/500.svg';

function Trusted() {
    return (
      <section className="trusted">
        <div className="trusted-container">
          <h2>The secure document sharing platform trusted by over 34,000 companies worldwide</h2>
          
          <div className="trusted-logos">
            <img src={airtableLogo} alt="Airtable" />
            <img src={accelLogo} alt="Accel" />
            <img src={greycroft} alt="Greycroft" />
            <img src={gartnerLogo} alt="Gartner" />
            <img src={five00Logo} alt="500 Startups" />
          </div>
          
          <div className="try-free">
            <p>Trusted by 34,000+ Companies</p>
            <a href="#" className="btn btn-secondary">Try for free</a>
          </div>
        </div>
      </section>
    );
  }
  
  export default Trusted;