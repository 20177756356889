import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaTrash, FaEye, FaEdit, FaLink, FaUsers } from "react-icons/fa";
import "../../css/dashboard/DashboardMain.css";
import { useAuth } from "../../context/AuthContext";
const API_URL = process.env.REACT_APP_API_URL;

const DashboardMain = () => {
  const [synclinkShare, setsynclinkShare] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const { user } = useAuth();

  // get token
  const getToken = () => {
    const name = "token=";
    const decodedCookie = decodeURIComponent(document.cookie);
    console.log("Full cookie string:", decodedCookie); // Debug line
    const cookieArray = decodedCookie.split(";");
    console.log("Cookie array:", cookieArray); // Debug line

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      // console.log(`Checking cookie: "${cookie}"`); // Debug line
      // console.log(`Starts with "${name}"?`, cookie.indexOf(name) === 0); // Debug line
      if (cookie.indexOf(name) === 0) {
        const value = cookie.substring(name.length, cookie.length);
        // console.log("Found value:", value); // Debug line
        return value;
      }
    }
    return null;
  };

  useEffect(() => {
    const fetchsynclinkShare = async () => {
      try {
        setLoading(true);
        // This endpoint should return links for the currently authenticated user
        const response = await axios.get(`${API_URL}/synclinkshare/user/me`, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: getToken(),
          },
          withCredentials: true,
        });
        setsynclinkShare(response.data);
      } catch (err) {
        console.error("Error fetching Synclinkshare:", err);
        setError("Failed to load your Synclinkshare. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchsynclinkShare();
  }, []);

  const handleDelete = async (linkId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this Synclinkshare? This action cannot be undone."
      )
    ) {
      try {
        setDeleting(linkId);
        await axios.delete(`${API_URL}/synclinkshare/${linkId}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: getToken(),
          },
          withCredentials: true,
        });
        // Remove the deleted link from the state
        setsynclinkShare((prev) =>
          prev.filter((link) => link.linkId !== linkId)
        );
      } catch (err) {
        console.error("Error deleting Synclinkshare:", err);
        alert("Failed to delete the Synclinkshare. Please try again.");
      } finally {
        setDeleting(null);
      }
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (loading) {
    return (
      <div className="my-links-container loading">
        <div className="loading-spinner"></div>
        <p>Loading your Synclinkshare...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="my-links-container error">
        <h2>Error</h2>
        <p>{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="retry-button"
        >
          Try Again
        </button>
      </div>
    );
  }

  const getCapitalizedFirstName = () => {
    if (!user) return "";

    // Determine which field to use (username, name, or email)
    let fullName =
      user.username || user.name || user.email?.split("@")[0] || "";

    // Extract just the first name (split by space and take the first part)
    const firstName = fullName.split(" ")[0];

    // Capitalize the first letter and make the rest lowercase
    return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
  };

  return (
    <div className="my-links-container">
      <div className="my-links-header">
        <h3>
          {" "}
          {user
            ? ` Welcome ${getCapitalizedFirstName()}`
            : "My Synclinkshare"}{" "}
        </h3>

        <Link to="/synclinkshare/create" className="create-link-button">
          <FaLink /> Create New Link
        </Link>
      </div>

      {synclinkShare.length === 0 ? (
        <div className="no-links">
          <p>You haven't created any Synclinkshare yet.</p>
          <Link to="/synclinkshare/create" className="create-first-link">
            Create Your First Synclinkshare
          </Link>
        </div>
      ) : (
        <div className="synclinkshare-grid">
          {synclinkShare.map((link) => (
            <div
              key={link.linkId}
              className="synclinkshare-card"
              style={{ borderTopColor: getRandomColor(link.linkId) }}
            >
              <div className="synclinkshare-card-header">
                <h3 className="synclinkshare-title">{link.title}</h3>
                <div className="synclinkshare-actions">
                  <Link
                    to={`/synclinkshare/update/${link.linkId}`}
                    className="edit-button"
                    title="Edit Synclinkshare"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaEdit />
                  </Link>

                  <button
                    className={`delete-button ${
                      deleting === link.linkId ? "deleting" : ""
                    }`}
                    onClick={() => handleDelete(link.linkId)}
                    disabled={deleting === link.linkId}
                    title="Delete Synclinkshare"
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>

              <div className="synclinkshare-content">
                <p className="synclinkshare-description">
                  {link.description || "No description provided"}
                </p>
                <div className="synclinkshare-details">
                  <div className="detail-item">
                    <FaEye className="detail-icon" />
                    <span className="detail-value">{link.views || 0}</span>
                    <span className="detail-label">Views</span>
                  </div>
                  <div className="detail-item">
                    <FaUsers className="detail-icon" />
                    <span className="detail-value">
                      {link.uniqueVisitors?.length || 0}
                    </span>
                    <span className="detail-label">Unique Visitors</span>
                  </div>
                </div>
              </div>

              <div className="synclinkshare-footer">
                <div className="synclinkshare-meta">
                  <div className="meta-item">
                    <span className="meta-label">Created:</span>
                    <span className="meta-value">
                      {formatDate(link.createdAt)}
                    </span>
                  </div>
                  <div className="meta-item">
                    <span className="meta-label">Files:</span>
                    <span className="meta-value">{link.files.length}</span>
                  </div>
                </div>
                <Link
                  to={`/synclinkshare/view/${link.linkId}`}
                  className="view-link-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Link
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// Function to generate a consistent color based on linkId
const getRandomColor = (linkId) => {
  const colors = [
    "#3498db", // blue
    "#2ecc71", // green
    "#e74c3c", // red
    "#f39c12", // orange
    "#9b59b6", // purple
    "#1abc9c", // teal
    "#d35400", // dark orange
    "#c0392b", // dark red
    "#27ae60", // dark green
    "#2980b9", // dark blue
  ];

  // Use the linkId to get a consistent color
  // This ensures the same link always gets the same color
  const charSum = linkId
    .split("")
    .reduce((sum, char) => sum + char.charCodeAt(0), 0);

  return colors[charSum % colors.length];
};

export default DashboardMain;
