import React from "react";
import { FaFileUpload, FaCheck, FaTimes, FaSpinner } from "react-icons/fa";
import "../../css/dashboard/FileUploadStatus.css";
import { getFileIconInfo, formatFileSize } from "../../pages/SyncLinkShareCreator"; // Reusing your helper functions

const FileUploadStatus = ({ files = [], onCancel }) => {
  if (!files || files.length === 0) return null;

  // Count completed uploads
  const completedCount = files.filter(
    (file) => file.progress === 100 || file.status === "completed"
  ).length;
  const hasCompleted = completedCount > 0;
  const allCompleted = completedCount === files.length;

  return (
    <div className="file-upload-status-container">
      <div className="file-upload-status-header">
        <div className="file-upload-status-title">
          <FaFileUpload className="upload-icon" />
          <span>
            {allCompleted
              ? "All files uploaded successfully"
              : `Uploading ${files.length} file${
                  files.length !== 1 ? "s" : ""
                }`}
          </span>
        </div>
        {allCompleted && (
          <button
            className="close-button"
            onClick={() => onCancel && onCancel()}
          >
            <FaTimes />
          </button>
        )}
      </div>

      <div className="file-upload-list">
        {files.map((file, index) => {
          const progress = file.progress || 0;
          const isComplete = progress === 100 || file.status === "completed";
          const isError = file.status === "error";
          const { icon, color } = getFileIconInfo(file);

          return (
            <div
              key={file.id || index}
              className={`file-upload-item ${
                isComplete ? "complete" : isError ? "error" : ""
              }`}
            >
              <div className="file-icon" style={{ color }}>
                {icon}
              </div>

              <div className="file-info">
                <div className="file-name">
                  {file.originalName || file.name}
                </div>
                <div className="file-details">
                  <span className="file-size">
                    {formatFileSize(file.size || file.sizeInBytes)}
                  </span>
                  {!isComplete && !isError && (
                    <span className="status-text">Uploading...</span>
                  )}
                  {isComplete && (
                    <span className="status-text success">Complete</span>
                  )}
                  {isError && (
                    <span className="status-text error">
                      {file.error || "Upload failed"}
                    </span>
                  )}
                </div>

                <div className="progress-bar-container">
                  <div
                    className={`progress-bar ${
                      isComplete ? "complete" : isError ? "error" : ""
                    }`}
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
              </div>

              <div className="upload-status-icon">
                {!isComplete && !isError && <FaSpinner className="spinner" />}
                {isComplete && <FaCheck className="success-icon" />}
                {isError && <FaTimes className="error-icon" />}
              </div>
            </div>
          );
        })}
      </div>

      {hasCompleted && (
        <div className="upload-summary">
          {completedCount} of {files.length} uploads complete
        </div>
      )}
    </div>
  );
};

export default FileUploadStatus;
