import '../../css/style.css';
import testimonialImage1 from '../../assets/testimonial1.jpg';
import testimonialImage2 from '../../assets/testimonial2.jpg';

function Testimonials() {
  return (
    <section className="testimonials">
      <div className="testimonials-container">
        <div className="testimonial">
          <p>"An efficient way to share content with confidence & control"</p>
          <div className="testimonial-author">
            <img src={testimonialImage1} alt="Mike Dinsdale" />
            <p>Mike Dinsdale, CFO, Gusto</p>
          </div>
        </div>
        
        <div className="testimonial">
          <p>"With SynclinkShare, I can see where investors are focusing, and where I am losing investors. Tracking who's seeing the deck and where their attention was focused has been super useful"</p>
          <div className="testimonial-author">
            <img src={testimonialImage2} alt="Sheel Mohnot" />
            <p>Sheel Mohnot, Partner, 500 Startups</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;