import Header from "../components/landingpage/Header";
import Hero from "../components/landingpage/Hero";
import Platform from "../components/landingpage/Platform";
import Features from "../components/landingpage/Features";
import Testimonials from "../components/landingpage/Testimonials";
import VirtualDataRooms from "../components/landingpage/VirtualDataRooms";
import Trusted from "../components/landingpage/Trusted";
import Footer from "../components/landingpage/Footer";

function LandingPage() {
  return (
    <div className="app">
      <Header />
      <Hero />
      <Platform />
      <VirtualDataRooms />
      <Testimonials />
      <Features />
      <Trusted />
      <Footer />
    </div>
  );
}

export default LandingPage;
